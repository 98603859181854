import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {OrderPlanning} from "../../../models/order-planning.interface";
import {LoadingPointStartEndHour} from "../../../models/planning.interface";
import {PlanningColumn, PlanningTooltipContentType} from "../../../models/wrappers/planning-timeline.interface";

@Component({
    selector: 'app-optimized-planning-table',
    templateUrl: './optimized-planning-table.component.html',
    styleUrl: './optimized-planning-table.component.scss'
})
export class OptimizedPlanningTableComponent implements OnChanges {
    @Input() timelines: OrderPlanning[] = [];
    @Input() loadingPointStartEndHour: LoadingPointStartEndHour | undefined;
    @Input() planningColumns: PlanningColumn[] = [];
    timeSlots: string[] = [];
    pistes: { id: string; name: string; tasks: OrderPlanning[]; grades: string[] | undefined }[] = [];
    schedulerStartTime: number = 0;
    schedulerEndTime: number = 0;
    tooltipTask: OrderPlanning | null = null;
    hoverOverIcon = false;
    protected readonly PlanningTooltipContentType = PlanningTooltipContentType;

    constructor() {
        this.generateTimeSlots();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['timelines'] || changes['planningColumns']) {
            this.initializePistes();
            this.timelines.forEach(timeline => {
                if (this.pistes[parseInt(timeline.dockId) - 1]?.tasks) {
                    this.pistes[parseInt(timeline.dockId) - 1].tasks.push(timeline);
                    this.pistes.forEach(piste => {
                        piste.grades?.sort((a, b) => {
                            const aValue = parseInt(a.split('/')[0]);
                            const bValue = parseInt(b.split('/')[0]);
                            return aValue - bValue;
                        });
                    });
                }
            });

        }
        if (changes['loadingPointStartEndHour'] && this.loadingPointStartEndHour) {
            this.schedulerStartTime = +this.transformUtcToLocalTime(this.loadingPointStartEndHour.openingHour).split(':')[0];
            this.schedulerEndTime = +this.transformUtcToLocalTime(this.loadingPointStartEndHour.closingHour).split(':')[0];
            this.generateTimeSlots();
        }
    }

    generateTimeSlots() {
        this.timeSlots = [];
        for (let hour = this.schedulerStartTime; hour < this.schedulerEndTime; hour++) {
            this.timeSlots.push(`${hour}h`);
            if (hour < this.schedulerEndTime) this.timeSlots.push(`${hour}h30`);
        }
    }

    initializePistes() {
        this.pistes = [];
        for (const element of this.planningColumns) {
            this.pistes.push({
                id: element.id,
                name: element.name,
                tasks: [],
                grades: element.grades
            });
        }
    }


    getTaskPosition(startTime: Date): string {
        const headerHeight = 95;
        const pixelsPerMinute = 100 / 30; // 100px per 30 minutes
        const hour = new Date(startTime).getUTCHours();
        const minutes = new Date(startTime).getUTCMinutes();
        // Calculate total minutes from the scheduler's start time
        const startMinutes = (hour - this.schedulerStartTime) * 60 + (minutes || 0);
        const taskTopPosition = startMinutes * pixelsPerMinute;
        return `${taskTopPosition + headerHeight}px`;
    }

    getTaskHeight(startTime: Date, endTime: Date): string {
        const pixelsPerMinute = 100 / 30; // 100px per 30 minutes
        const startHour = new Date(startTime).getUTCHours();
        const startMinutes = new Date(startTime).getUTCMinutes();
        const endHour = new Date(endTime).getUTCHours();
        const endMinutes = new Date(endTime).getUTCMinutes();

        const durationMinutes =
            (endHour - startHour) * 60 + (endMinutes || 0) - (startMinutes || 0);

        return `${durationMinutes * pixelsPerMinute - 2}px`;
    }

    getGradeClass(grade: string): string {
        const gradeClasses: { [key: string]: string } = {
            '35/50': 'grade-35-50',
            '50/70': 'grade-50-70',
            '70/100': 'grade-70-100',
            '160/220': 'grade-160-220'
        };
        return gradeClasses[grade] || '';
    }

    transformUtcToLocalTime(utcTime: string): string {
        const utcDate = new Date(`1970-01-01T${utcTime}Z`);
        const localDate = new Date(utcDate.toLocaleString('en-US', {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}));
        return localDate.toTimeString().split(' ')[0];
    }

    setTooltipTask(task: OrderPlanning | null) {
        this.tooltipTask = task;
    }
}
