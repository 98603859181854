export interface PlanningColumnCell {
    columnId: string;
    mainText: string;
    secondaryText: string;
    thirdText: string;
    cellId: string;
    startDate: Date;
    endDate: Date;
    badgedText: string;
    realLoadingDate: Date | null;
    emptyCell?: boolean;
    initialStartDate: Date | null;
    initialEndDate: Date | null;
}

export interface PlanningColumn {
    id: string;
    name: string;
    badgedTexts: string[];
    grades?: string[];
    cells: PlanningColumnCell[];
}

export interface PlanningTimelineStep {
    id: string;
    displayName: string;
    value: string;
}

export interface PlanningTimeline {
    id: string;
    range: string;
    steps: PlanningTimelineStep[];
    columns: PlanningColumn[];
    freeSlots: number;
}

export interface PlanningTooltipContent {
    displayName: string;
    cellPropertyName: keyof PlanningColumnCell;
    displaySecondName?: string;
    cellPropertySecondName?: keyof PlanningColumnCell;
    conditionDisplay?: TooltipDisplayCondition;
    type: PlanningTooltipContentType;
}

export type TooltipDisplayCondition = (arg: PlanningColumnCell) => boolean;

export enum PlanningTooltipContentType {
    DATE, TRUNCATE, DEFAULT
}
