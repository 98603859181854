<div class="segmented-controls p-1 d-flex w-fit">
    <div
            class="py-2 px-3 text-center pointer uppercase-first-letter"
            [class.active]="selectedControlId === control.id"
            (click)="setSelectedControl(control)"
            *ngFor="let control of controls"
            onkeypress=""
    >
        {{ formatDate(control.openDay) }}
    </div>
</div>

